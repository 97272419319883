<template>
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <h5>{{ $t('menu.pendingInstallations') }}</h5>
                <Toast />
                <DataTable v-if="items.length > 0" :value="items" :paginator="true" :rows="perPage" :loading="loading" class="p-mt-3" ref="datatable" responsiveLayout="scroll" :paginatorBottom="true">
                    <Column
                        ><template #body="slotProps">
                            <span class="indexColumn">{{ slotProps.index + 1 }}</span>
                        </template>
                    </Column>
                    <Column field="TerminalId" :header="$t('system.id')"></Column>
                    <Column field="ShopName" :header="$t('system.shop')" :sortable="true"></Column>
                    <Column field="RegistrationComments" :header="$t('system.registrationComments')"></Column>
                    <Column field="RequestDate" :header="$t('system.requestDate')" :sortable="true">
                        <template #body="{ data }">{{ formatCSDateAndTime(data.RequestDate) }}</template>
                    </Column>
                    <Column :exportable="false" class="p-text-right">
                        <template #body="slotProps">
                            <Button icon="pi pi-check-square" class="p-button-rounded p-button-success p-mr-2" @click="activateTerminal(slotProps.data.TerminalId)" />
                        </template>
                    </Column>
                </DataTable>
                <p v-else>{{ $t('system.noPendingInstallations') }}</p>
            </div>
        </div>
    </div>
</template>
<script>
import { BoApi } from '../../service/BoApi';
import { uiSettings } from '../../settings/uiSettings';
export default {
    data() {
        return {
            tableHeight: uiSettings.tableHeight,
            items: [],
            BoApi: new BoApi(),
            perPage: uiSettings.tableRows,
            loading: true,
        };
    },
    mounted() {
        this.getPendingActivations();
    },
    methods: {
        activateTerminal(terminalId) {
            this.okModal = false;
            this.errorModal = false;
            this.BoApi.authorizeTerminal(terminalId)
                .then(() => {
                    this.$toast.add({ severity: 'success', summary: this.$t('system.alerts.terminalAuthorizationOk'), life: this.getToastLifeSeconds() });
                    this.getPendingActivations();
                })
                .catch((error) => {
                    this.checkForUnauthorizedError(error);
                    this.$toast.add({ severity: 'error', summary: this.$t('system.alerts.terminalAuthorizationError'), life: this.getToastLifeSeconds() });
                });
        },
        getPendingActivations() {
            this.loading = true;
            this.BoApi.getPendingActivationRequests()
                .then((response) => {
                    this.items = response.data;
                    this.loading = false;
                })
                .catch((error) => {
                    this.checkForUnauthorizedError(error);
                });
        },
    },
};
</script>
